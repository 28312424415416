<template>
  <a-result
    status="403"
    title="未加入空间"
    sub-title="请联系空间管理员将你加入空间"
  >
    <template #extra>
      <a-button type="primary" icon="poweroff" @click="logout()">
        重新登陆
      </a-button>
    </template>
  </a-result>
</template>

<script>
import store from "@/store";

export default {
  beforeRouteEnter(to, from, next) {
    const spaceInfo = store.spaceInfo || {};
    // 主管理员或者空间未标记删除就不要进来凑热闹了
    if (spaceInfo.isAdmin) return next("/");
    if (!spaceInfo.isPreDelete) return next("/");
    next();
  },
  methods: {
    async logout() {
      await store.logout();
      this.$router.replace({ name: "login" });
    }
  }
};
</script>
